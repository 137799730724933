import '../../../assets/css/search.css';
import Header from "components/Headers/Header";
import Footer from "components/Footers/Footer";
import UserCard from "components/User/UserCard";
import React, { useState, useEffect } from 'react';
import Axios from 'axios';

// Utils
import { createAlert } from 'utils/MainUtils';

function Matching() {
  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-user-matching`, {
          user_id: localStorage.getItem('user_id')
        }, {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        });

        const usersWithImages = await Promise.all(response.data.map(async (user) => {
          const imgResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-user-img`, {
            user_id: user.id,
            img_seq: 1
          }, {
            headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
          });

          return {
            ...user,
            img: imgResponse.data.image
          };
        }));

        setUserList(usersWithImages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        createAlert("FAILED", error.message);
      }
    };

    initialize();
  }, []);

  return (
    <div className="Matching">
      <Header />
      {loading ? (
        <div className="preloader-wrapper"><div className="preloader"></div></div>
      ) : (
        <UserCard userList={userList} message={"Matching"} style={{}} />
      )}
      <Footer isPadding={false} />
    </div>
  );
}

export default Matching;