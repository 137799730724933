import React, { useEffect, useState } from 'react';
import Axios from 'axios';

// Component
import '../../../assets/css/home.css';
import Header from "components/Headers/Header";
import Footer from "components/Footers/Footer";
import UserCard from 'components/User/UserCard';
// import image from "assets/images/background.jpg";

// Utils
import { createAlert } from 'utils/MainUtils';

function Home() {
  // const [countMan, setCountMan] = useState(0);
  // const [countWoman, setCountWoman] = useState(0);
  // const [countOther, setCountOther] = useState(0);
  // const [displayCountMan, setDisplayCountMan] = useState(0);
  // const [displayCountWoman, setDisplayCountWoman] = useState(0);
  // const [displayCountOther, setDisplayCountOther] = useState(0);
  const [imagePreview, setImagePreview] = useState(null);
  const [userList, setUserList] = useState([]);
  const [language, setLanguage] = useState('en'); // ตั้งค่าภาษาเริ่มต้น

  useEffect(() => {
    initialize();
  }, [language]); // เพิ่ม language เป็น dependency เพื่อให้มีการเรียกใช้งานใหม่เมื่อภาษาเปลี่ยน

  const initialize = async () => {
    try {
      // Update API call to include gender filter
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-category`, {}, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      // Limit user list to 8 users
      const limitedUsers = response.data.slice(0, 8);

      const usersWithImages = await Promise.all(limitedUsers.map(async (user) => {
        const imgResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-user-img`, {
          user_id: user.id,
          img_seq: 1
        }, {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        });

        return {
          ...user,
          img: imgResponse.data.image
        };
      }));

      setUserList(usersWithImages);

      const image = await getSettingImg('home');
      setImagePreview(image);
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  const getSettingImg = async (type) => {
    const imgResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-setting-img`, {
      type: type
    }, {
      headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
    });
    return imgResponse.data.image;
  };

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  // useEffect(() => {
  //   animateCount(setDisplayCountMan, countMan);
  //   animateCount(setDisplayCountWoman, countWoman);
  //   animateCount(setDisplayCountOther, countOther);
  // }, [countMan, countWoman, countOther]);
  // const initialize = async () => {
  //   try {
  //     const headers = { headers: { key: process.env.REACT_APP_ANALYTICS_KEY } };
  //     const genders = ['M', 'F', 'LB', 'TG'];
  //     const genderCounts = await Promise.all(
  //       genders.map(gender =>
  //         Axios.post(
  //           `${process.env.REACT_APP_API_URL}/api/count-gender`,
  //           { gender },
  //           headers
  //         )
  //       )
  //     );

  //     const counts = genderCounts.map(response => response.data.userCount);
  //     const totalCount = counts.reduce((acc, count) => acc + count, 0);
  //     const percentages = counts.map(count => Math.round((count / totalCount) * 100));

  //     setCountMan(percentages[0]);
  //     setCountWoman(percentages[1]);
  //     setCountOther(percentages[2] + percentages[3]);

  //     const image = await getSettingImg('home');
  //     setImagePreview(image);
  //   } catch (error) {
  //     createAlert('FAILED', error.message);
  //   }
  // };
  // const animateCount = (setter, targetCount) => {
  //   let currentCount = 0;
  //   const step = Math.ceil(targetCount / 100);

  //   const intervalId = setInterval(() => {
  //     currentCount += step;
  //     if (currentCount >= targetCount) {
  //       currentCount = targetCount;
  //       clearInterval(intervalId);
  //     }
  //     setter(currentCount);
  //   }, 20);
  // };

  return (
    <div className="Home">
      <Header />
      <section id="hero" style={{ backgroundImage: `url(${imagePreview})` }}>
        <div className="container text-center">
          <div className="col-md-6 mt-5">
            <h2 className="display-1 text-uppercase text-white title-slide">Thai Norway</h2>
            <h2 className="display-10 text-uppercase text-white title-slide">Start Something Real</h2>
            <br />
            <b className="display-10 text-white title-slide" hidden={localStorage.getItem('token') ? true : false}>Who attracts you the most?</b>
            <br />
            <br />
            <div className="row" hidden={localStorage.getItem('token') ? true : false}>
              <div className="col-sm-12 col-md-6 mt-2 px-1 d-flex justify-content-center mb-2">
                <button onClick={() => window.location.replace("/register")}
                  type="button" className="btn btn-danger" style={{ width: "100%" }}>Man</button>
              </div>
              <div className="col-sm-12 col-md-6 mt-2 px-1 d-flex justify-content-center mb-2">
                <button onClick={() => window.location.replace("/register")}
                  type="button" className="btn btn-danger" style={{ width: "100%" }}>Women</button>
              </div>
              <div className="col-sm-12 col-md-6 mt-2 px-1 d-flex justify-content-center mb-2">
                <button onClick={() => window.location.replace("/register")}
                  type="button" className="btn btn-danger" style={{ width: "100%" }}>Ladyboy</button>
              </div>
              <div className="col-sm-12 col-md-6 mt-2 px-1 d-flex justify-content-center mb-2">
                <button onClick={() => window.location.replace("/register")}
                  type="button" className="btn btn-danger" style={{ width: "100%" }}>Transgender</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="container text-center mt-5">
        <h2 className="display-4 text-uppercase mb-4">
          {language === 'en' ? 'Our Inspiration' :
            language === 'th' ? 'แรงบันดาลใจของเรา' :
              'Vår inspirasjon'}
        </h2>
        <p className="lead">
          {language === 'en' ? (
            <>
              Many people might wonder why I decided to venture into the matchmaking business. The inspiration came from observing the rising divorce rates among Thai women married to Norwegian men. Many of these women end up as single mothers. I feel sorry for the children born into these situations, being shuffled back and forth like a football.
              <br /><br />
              The concept has been around since 2017 (2560) because it helped Thai people who were being taken advantage of by their Norwegian husbands. I once testified in court, resulting in a friend receiving alimony and being able to stay in Norway. This experience sparked the idea that clear information must be provided to women before they come, so they don't become victims. There are also cases of Norwegian men being exploited by their Thai wives until they are broke. This is the inspiration behind the desire to protect the interests of both parties.
              <br /><br />
              We are not the police, but if we provide accurate information to women, they won't have unrealistic expectations. Future problems should be reduced. When I had sufficient finances, I used my own money to create a website. The main objective is not to generate income, but to empower women to improve their lives on their own. If you are hoping to find a rich husband, let me tell you that you are in the wrong place.
              <br /><br />
              Norway is a very nice country to live in, but the cost of living is quite high. Therefore, the idea of coming here to be a housewife and relying on your husband is not realistic. Here, both partners typically need to work. If you are hardworking, Norway offers opportunities to earn a living and send money back home. Partner expenses are usually split 50:50. Norwegian people love family very much. You can't predict what kind of partner you will get, but if you know yourself and your partner well, you can succeed in any situation. Be yourself and communicate your needs clearly. Don’t hide things or do whatever it takes just to get what you want. If problems arise, you will be the one who must deal with them.
              <br /><br />
              This website is suitable for those who want to build a family by working together and creating a livelihood. It is not intended for those seeking a foreigner to take care of them. We will provide career training to prepare you in terms of language and other skills. If you need assistance with registration, we will help you register for free. Currently, we are not accepting male registrations. Male registration will be allowed once we have at least a few hundred female members.
              <br /><br />
              If this aligns with your goals and you want to improve your life, you are welcome to join.
              <br /><br />
              Welcome, everyone!<br />
              <a href="http://www.thainorwaydate.com" target="_blank" rel="noopener noreferrer">www.thainorwaydate.com</a>
            </>
          ) : language === 'th' ? (
            <>
              หลายคนอาจสงสัยว่าทำไมถึงมาจับธุรกิจหาคู่ จริงๆแรงบันดาลใจของการทำเรื่องนี้มาจากการได้เห็นคนไทยที่แต่งงานกับชาวนอร์เวย์ แล้วมีปัญหาการหย่าร้างที่สูงขึ้น สาวๆกลายเป็นแม่เลี้ยงเดี่ยว และ สงสารเด็กๆ ที่เกิดมาแล้วต้องถูกแบ่งกันเลี้ยงแบบโยนไปมาเหมือนลูกฟุตบอล
              <br /><br />
              แนวคิดมีมาตั้งแต่ปี2017 (2560)เพราะต้องช่วยเหลือคนไทยจากสามีคนนอสที่ถูกเอาเปรียบ เคยเป็นพยานขึ้นศาลจนน้องได้ค่าเลี้ยงดูและอยู่ต่อ จากนั้นจุดประกายว่าต้องให้ข้อมูลที่ชัดเจนกับสาวๆก่อนมา จะได้ไม่ตกเป็นเหยื่อ และเคสของคนนอสเจอเมียไทยปอกลอกจนหมดตัว นี่คือแรงบันดาลใจที่ต้องการพิทักษ์ผลประโยชน์ทั้งสองฝ่าย
              <br /><br />
              เราไม่ใช่ตำรวจแต่ถ้าเราให้ข้อมูลที่ถูกต้องกับสาวๆจะได้ไม่ต้องคาดหวังมาก ปัญหาในอนาคตน่าจะลดลง เมื่อพอมีกำลังทรัพย์จึงใช้ทุนส่วนตัวทำเว็บขึ้นมา วัตถุประสงค์หลักไม่ใช่รายได้ แต่ตั้งใจให้สาวๆมีโอกาสในชีวิตที่ดีด้วยมือตัวเอง ถ้าคุณหวังว่าจะหาสามีรวยๆ แล้วนั่งกินนอนกินให้สามีเลี้ยง ขอบอกไว้ก่อนว่ามาผิดที่คะ
              <br /><br />
              ประเทศนอร์เวย์เป็นประเทศที่น่าอยู่มาก แต่มีค่าครองชีพที่สูงมากดังนั้น การคิดว่าจะมาเป็นมาดามให้ผัวเลี้ยงไม่ใช่เลยคะ ที่นี่ต้องช่วยกันทํามาหากินผัวหาบเมียคอน ถ้าคุณเป็นคนขยัน ที่นี่คือโอกาสให้คุณทำมาหากิน ส่งให้ทางบ้านด้วยเงินของคุณ ต้องช่วยกันจ่าย50:50 ค่าใช้จ่ายในบ้าน คนที่นี่รักครอบครัวมาก เราไม่รู้ชะตาฟ้าลิขิตจะพาเราเจอคู่แบบไหน รู้เขารู้เรารบชนะทุกครั้ง เป็นตัวเองบอกความต้องการที่ชัดเจน อย่าปกปิดหรือทำอะไรก็ได้เพื่อให้ได้มา อย่าทำแบบนั้น เพราะเมื่อเป็นปัญหา เราเองจะเดือดร้อน
              <br /><br />
              เว็บนี้เหมาะกับคนที่ต้องการมีครอบครัวแบบช่วยกันทํามาหากินสร้างครอบครัว ไม่เหมาะกับคนที่จะหาแบบให้ฝรั่งมาเลี้ยง เราจะแนะนำการฝึกอาชีพ เพื่อเตรียมความพร้อมทั้งภาษาและด้านอื่นๆ ใครลงทะเบียนไม่เป็นเราช่วยลงฟรี เรายังไม่เปิดลงทะเบียนฝ่ายชายคะ รอให้สมาชิกฝ่ายหญิงสักหลักร้อยขึ้นไปจึงจะให้ฝ่ายชายมาลงคะ
              <br /><br />
              ถ้าคุณคิดว่าใช่ อยากมีชีวิตที่ดีขึ้น ยินดีต้อนรับค่ะ
              <br /><br />
              ยินดีต้อนรับทุกคนนะคะ<br />
              <a href="http://www.thainorwaydate.com" target="_blank" rel="noopener noreferrer">www.thainorwaydate.com</a>
            </>
          ) : (
            <>
              Mange mennesker undrer seg kanskje over hvorfor jeg valgte å gå inn i matchmaking business. Inspirasjonen min kom fra å observere den økende skilsmisseraten blant thailandske kvinner med norske menn. Mange av disse kvinnene ender opp som enslige mødre. Jeg synes synd på barna som ble født inn i disse situasjonene, og må bli delt opp og kastet rundt som en fotball.
              <br /><br />
              Konseptet har eksistert siden 2017 (2560) fordi det hjalp thailandske kvinner som ble utnyttet av sine norske ektemenn. Jeg vitnet en gang i retten, noe som resulterte i at en venn fikk underholdsbidrag og kunne bli i Norge. Denne erfaringen utløste ideen om at tydelig informasjon må gis til kvinner før de kommer, slik at de ikke blir ofre. Det finnes også tilfeller hvor norske menn blir utnyttet av sine thailandske koner til de er blakke. Dette er inspirasjonen bak ønsket om å beskytte interessene til begge parter.
              <br /><br />
              Vi er ikke politi. Men hvis vi gir nøyaktig informasjon til kvinner, vil de ikke ha urealistiske forventninger. Fremtidige problemer kan reduseres. Da jeg hadde tilstrekkelig økonomi, brukte jeg mine egne penger til å opprette en nettside. Hovedmålet er ikke å generere inntekt, men å gi kvinner muligheten til å forbedre livene sine på egen hånd. Hvis du håper å finne en rik ektemann, la meg si deg at da du er på feil sted.
              <br /><br />
              Norge er et veldig fint land å bo i selv om levekostnadene er ganske høye. Derfor er ideen om å komme hit som frue og bli tatt vare på av din mann ikke realistisk. Her må begge parter vanligvis jobbe. Og hvis du er arbeidsom, gir Norge deg muligheten til å tjene til livets opphold og sende penger hjem. Parets utgifter deles vanligvis 50:50. Nordmenn elsker familien sin veldig høyt. Du kan ikke forutsi hvilken type partner du vil få. Men hvis du kjenner deg selv og partneren din godt, kan du lykkes i enhver situasjon. Vær deg selv og kommuniser dine behov tydelig. Ikke skjul ting eller gjør hva som helst bare for å få det du vil ha. Hvis problemer oppstår, vil du være den som må håndtere dem.
              <br /><br />
              Dette nettstedet er egnet for dem som ønsker å bygge en familie ved å jobbe sammen og skape et levebrød. Det er ikke ment for dem som søker en utlending til å ta vare på seg. Vi vil gi deg karrieretrening for å forberede deg når det gjelder språk og andre ferdigheter. Hvis du trenger hjelp med registreringen, vil vi gratis hjelpe deg med å registrere deg. For øyeblikket aksepterer vi ikke mannlige registreringer. Mannlige registreringer vil bli tillatt når vi har minst et par hundre kvinnelige medlemmer.
              <br /><br />
              Hvis dette samsvarer med dine mål og du ønsker å forbedre livet ditt, er du velkommen til å bli med.
              <br /><br />
              Velkommen alle sammen!<br />
              <a href="http://www.thainorwaydate.com" target="_blank" rel="noopener noreferrer">www.thainorwaydate.com</a>
            </>
          )}
        </p>
        <div className="mt-4">
          <img
            src="images/thai-flag.png"
            alt="Thai Flag"
            className={`img-fluid me-2 language-flag ${language === 'th' ? 'active' : ''}`}
            onClick={() => handleLanguageChange('th')}
          />
          <img
            src="images/english-flag.png"
            alt="English Flag"
            className={`img-fluid me-2 language-flag ${language === 'en' ? 'active' : ''}`}
            onClick={() => handleLanguageChange('en')}
          />
          <img
            src="images/norway-flag.png"
            alt="Norway Flag"
            className={`img-fluid language-flag ${language === 'no' ? 'active' : ''}`}
            onClick={() => handleLanguageChange('no')}
          />
        </div>
      </section>
      <UserCard userList={userList} message={""} style={{ marginTop: '-50px' }} />
      {/* <section id="features">
        <div className="feature-box container">
          <div className="row">
            <div className="col-md-4 col-sm-6 mb-5 mb-md-0">
              <div className="feature-item py-5 rounded-4">
                <div className="feature-detail text-center">
                  <h2 className="feature-title">{displayCountMan}%</h2>
                  <h6 className="feature-info text-uppercase">MAN</h6>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-5 mb-md-0">
              <div className="feature-item py-5 rounded-4">
                <div className="feature-detail text-center">
                  <h2 className="feature-title">{displayCountWoman}%</h2>
                  <h6 className="feature-info text-uppercase">WOMEN</h6>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-5 mb-md-0">
              <div className="feature-item py-5 rounded-4">
                <div className="feature-detail text-center">
                  <h2 className="feature-title">{displayCountOther}%</h2>
                  <h6 className="feature-info text-uppercase">OTHER</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Footer isPadding={false} />
    </div>
  );
}

export default Home;