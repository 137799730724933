import React from 'react';

function FormAction({ sectionIndex, isBackLoading, isNextLoading, isButtonDisabled, handleBackClick, handleNextClick }) {

    const handleRegisterClick = () => {
        document.getElementById('confirm-finished-btn').click();
    };

    return (
        <div className="row">
            <div className="col-12 col-md-6">
                {sectionIndex !== 1 && (
                    <div className="text-center mt-5">
                        <button
                            onClick={handleBackClick}
                            disabled={isButtonDisabled}
                            className="btn btn-lg btn-danger position-relative"
                            style={{ width: '100%' }}
                        >
                            <span style={{ visibility: isBackLoading ? 'hidden' : 'visible' }}>BACK</span>
                            {isBackLoading && (
                                <div className="position-absolute top-50 start-50 translate-middle">
                                    <div className="spinner-border text-light" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            )}
                        </button>
                    </div>
                )}
            </div>
            <div className="col-12 col-md-6">
                <div className="text-center mt-5">
                    {sectionIndex < 4 ? (
                        <button
                            disabled={isButtonDisabled}
                            type="submit"
                            className="btn btn-lg btn-danger"
                            style={{ width: '100%' }}
                        >
                            {isNextLoading ? (
                                <div className="spinner-border text-light" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                'NEXT'
                            )}
                        </button>
                    ) : (
                        <button
                            onClick={handleRegisterClick}
                            disabled={isButtonDisabled}
                            className="btn btn-lg btn-danger"
                            style={{ width: '100%' }}
                        >
                            REGISTER
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FormAction;