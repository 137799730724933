import AbstractToggle from 'components/Toggle/AbstractToggle';

function FormSection2({
    height, setHeight,
    weight, setWeight,
    shape, setShape,
    maritalStatus, setMaritalStatus,
    wantChild, setWantChild,
    numChild, setNumChild,
    liveWith, setLiveWith,
    influencePerson, setInfluencePerson,
    ethnicity, setEthnicity,
    religion, setReligion,
    zodiacSign, setZodiacSign,
    education, setEducation,
    valuePerson, setValuePerson
}) {
    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group mt-3 text-center">
                        <h2>Personal Information</h2>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <div className="form-group mt-3">
                                <label id="ip-height" htmlFor="height" className="required-label">Height (cm.)</label>
                                <input
                                    className="form-control form-control-lg"
                                    id="height"
                                    type="number"
                                    name="height"
                                    value={height}
                                    required={true}
                                    onChange={(e) => setHeight(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <div className="form-group mt-3">
                                <label id="ip-weight" htmlFor="weight" className="required-label">Weight (kg.)</label>
                                <input
                                    className="form-control form-control-lg"
                                    id="weight"
                                    type="number"
                                    name="weight"
                                    value={weight}
                                    required={true}
                                    onChange={(e) => setWeight(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <label id="ip-shape">Shape</label>
                        <AbstractToggle rf={"SHAPE"} value={shape} setValue={setShape} disabled={false} multiple={false} />
                    </div>
                    <div className="form-group mt-3">
                        <label id="at-marital" className="required-label">Marital Status</label>
                        <AbstractToggle rf={"MARITAL_STAT"} value={maritalStatus} setValue={setMaritalStatus} disabled={false} multiple={false} />
                    </div>
                    <div className="form-group mt-3">
                        <label id="at-kids" className="required-label">Do you want children?</label>
                        <AbstractToggle rf={"KIDS"} value={wantChild} setValue={setWantChild} disabled={false} multiple={false} />
                    </div>
                    <div className="form-group mt-3">
                        <label id="ip-numChild" htmlFor="num_child">Number of children?</label>
                        <input
                            className="form-control form-control-lg"
                            id="num_child"
                            type="number"
                            name="num_child"
                            value={numChild}
                            required={false}
                            onChange={(e) => setNumChild(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label id="at-liveWith">Who do you live with?</label>
                        <AbstractToggle rf={"LIVE_WITH"} value={liveWith} setValue={setLiveWith} disabled={false} multiple={false} />
                    </div>
                    <div className="form-group mt-3">
                        <label id="at-influences">Who influences you the most?</label>
                        <AbstractToggle rf={"INFLUENCES_FACTOR"} value={influencePerson} setValue={setInfluencePerson} disabled={false} multiple={false} />
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group mt-3">
                        <label id="at-ethnicity" className="required-label">Ethnicity</label>
                        <AbstractToggle rf={"ETHNICITY"} value={ethnicity} setValue={setEthnicity} disabled={false} multiple={false} />
                    </div>
                    <div className="form-group mt-3">
                        <label id="at-religion" className="required-label">Religion</label>
                        <AbstractToggle rf={"RELIGION"} value={religion} setValue={setReligion} disabled={false} multiple={false} />
                    </div>
                    <div className="form-group mt-3">
                        <label id="at-zodiac">What is your zodiac sign?</label>
                        <AbstractToggle rf={"ZODIAC"} value={zodiacSign} setValue={setZodiacSign} disabled={false} multiple={false} />
                    </div>
                    <div className="form-group mt-3">
                        <label id="at-education" className="required-label">Education</label>
                        <AbstractToggle rf={"EDUCATION"} value={education} setValue={setEducation} disabled={false} multiple={false} />
                    </div>
                    <div className="form-group mt-3">
                        <label>Tell us what you value in a person? <span style={{ color: "red" }}>(Can choose more than 1)</span></label>
                        <AbstractToggle rf={"PERSON_VALUE"} value={valuePerson} setValue={setValuePerson} disabled={false} multiple={true} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default FormSection2;
