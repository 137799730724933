import AbstractToggle from '../../components/Toggle/AbstractToggle';
import FormImg from './FormImg';

function FormSection1({ mode, gender, setGender, name, setName, occupation, setOccupation, birthdate, setBirthdate, country, setCountry, caption, setCaption, cEmail, setCEmail, line, setLine, whatsApp, setWhatsApp, facebook, setFacebook, phone, setPhone, userImgList, setUserImgList }) {
    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group mt-3 text-center">
                        <h2>User Information</h2>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group mt-3">
                        <label id="ip-name" htmlFor="name" className="required-label">Your Name</label>
                        <input
                            className="form-control form-control-lg"
                            id="name"
                            name="name"
                            type="text"
                            value={name}
                            required={true}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label id="at-gender" className="required-label">Gender</label>
                        <AbstractToggle rf={"GENDER"} value={gender} setValue={setGender} disabled={false} multiple={false} />
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="occupation">Occupation</label>
                        <input
                            className="form-control form-control-lg"
                            id="occupation"
                            type="text"
                            name="occupation"
                            value={occupation}
                            required={false}
                            onChange={(e) => setOccupation(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label id="ip-email" htmlFor="c_email">Email</label>
                        <input
                            className="form-control form-control-lg"
                            id="c_email"
                            type="email"
                            name="c_email"
                            value={cEmail}
                            required={false}
                            onChange={(e) => setCEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label id="ip-whatsApp" htmlFor="whatsApp">What's App</label>
                        <input
                            className="form-control form-control-lg"
                            id="whatsApp"
                            type="text"
                            name="whatsApp"
                            value={whatsApp}
                            required={false}
                            onChange={(e) => setWhatsApp(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label id="ip-phone" htmlFor="phone">Tel</label>
                        <input
                            className="form-control form-control-lg"
                            id="phone"
                            type="text"
                            name="phone"
                            value={phone}
                            required={false}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group mt-3">
                        <label id="ip-birth" htmlFor="birth" className="required-label">Your Birthday</label>
                        <input
                            className="form-control form-control-lg"
                            id="birth"
                            type="date"
                            name="birth"
                            dateformat="dd/MM/yyyy"
                            value={birthdate}
                            required={true}
                            onChange={(e) => setBirthdate(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label id="ip-country" htmlFor="country" className="required-label">Country</label>
                        <input
                            className="form-control form-control-lg"
                            id="country"
                            type="text"
                            name="country"
                            value={country}
                            required={true}
                            onChange={(e) => setCountry(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label id="ip-caption" htmlFor="caption">Caption</label>
                        <input
                            className="form-control form-control-lg"
                            id="caption"
                            type="text"
                            name="caption"
                            value={caption}
                            required={false}
                            onChange={(e) => setCaption(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label id="ip-line" htmlFor="line">Line</label>
                        <input
                            className="form-control form-control-lg"
                            id="line"
                            type="text"
                            name="line"
                            value={line}
                            required={false}
                            onChange={(e) => setLine(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label id="ip-facebook" htmlFor="facebook">Facebook</label>
                        <input
                            className="form-control form-control-lg"
                            id="facebook"
                            type="text"
                            name="facebook"
                            value={facebook}
                            required={false}
                            onChange={(e) => setFacebook(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="row mt-5" hidden={mode !== 'EDIT'}>
                <br />
                <hr />
                <div className="col-12 col-sm-6 col-lg-8">
                    <div className="row">
                        <div className="col-12">
                            <div className="small-images-container">
                                {/* แสดงรูปภาพที่ได้จาก userImgList ในลำดับ 1, 2, และ 3 */}
                                <FormImg userId={localStorage.getItem('user_id')} userImgList={userImgList} setUserImgList={setUserImgList} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FormSection1;