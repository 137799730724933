import React, { useState, useEffect } from 'react';
import Axios from "axios";

// Utils
import { getDataByUserId, getUserSys, getValue2Text, updateStatusActive, createAlert, handleEditSection } from 'utils/MainUtils';

function Section1({ userId, isOwnerProfile }) {
    const [userData, setUserData] = useState({
        fullname: '',
        age: '',
        gender: '',
        birthdate: '',
        country: '',
        caption: '',
        occupation: '',
        email: '',
        line: '',
        whatsApp: '',
        facebook: '',
        phone: '',
    });
    const [userImgList, setUserImgList] = useState([]);
    const [selectedImg, setSelectedImg] = useState(null);
    const [renderIgnore, setRenderIgnore] = useState(false);
    const [renderInterest, setRenderInterest] = useState(true);
    const [renderMatch, setRenderMatch] = useState(false);
    const [renderRemove, setRenderRemove] = useState(false);
    const [renderSubscribe, setRenderSubscribe] = useState(false);
    const [hideProfile, setHideProfile] = useState(false);
    const [isOwnerHidden, setIsOwnerHidden] = useState(false);
    const [isMember, setIsMember] = useState(false);
    const [isMatching, setIsMatching] = useState(false);

    useEffect(() => {
        const initialize = async () => {
            try {
                await manageInterest(localStorage.getItem('user_id'));

                // USER_INFO
                const userInfo = await getDataByUserId("USER_INFO", userId, isOwnerProfile ? "" : "Y");
                if (userInfo && userInfo.length > 0) {
                    const user = userInfo[0];
                    const birthdate = new Date(user.birthdate);
                    const age = new Date().getFullYear() - birthdate.getFullYear() - (new Date().getMonth() < birthdate.getMonth() || (new Date().getMonth() === birthdate.getMonth() && new Date().getDate() < birthdate.getDate()) ? 1 : 0);

                    setUserData(prevData => ({
                        ...prevData,
                        fullname: user.name,
                        age,
                        birthdate: user.birthdate,
                        country: user.country,
                        occupation: user.occupation,
                        caption: user.caption,
                        email: user.email,
                        line: user.line,
                        whatsApp: user.whats_app,
                        facebook: user.facebook,
                        phone: user.phone
                    }));

                    // หาข้อมูลสำหรับเพศ
                    const genderRf = await getUserSys("GENDER", userId);
                    const genderText = genderRf && genderRf.length > 0 ? await getValue2Text("GENDER", genderRf[0].ref_code) : '-';
                    setUserData(prevData => ({ ...prevData, gender: genderText }));
                } else {
                    setHideProfile(true);
                }

                // Check Hidden For OwnerProfile
                if (isOwnerProfile) {
                    const ownerProfile = await getDataByUserId("USER_INFO", userId, "Y");
                    if (ownerProfile && ownerProfile.length > 0) {
                        setIsOwnerHidden(false);
                    } else {
                        setIsOwnerHidden(true);
                    }
                }

                // Check Member
                const member = await getDataByUserId('USER_MEMBER', localStorage.getItem('user_id'));
                if (member && member.length > 0) {
                    let filteredMembers = member.filter(member => member.member_status === 'N');
                    if (filteredMembers.length === 0) {
                        setIsMember(false);
                    } else {
                        setIsMember(true);
                    }
                }

                // Check Matching
                const match = await Axios.post(`${process.env.REACT_APP_API_URL}/api/manage-interest`, {
                    user_id: userId,
                    user_interest_id: localStorage.getItem('user_id'),
                    action: 'check-response'
                }, {
                    headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
                })
                if (match.data.result === 1) {
                    setIsMatching(true);
                } else {
                    setIsMatching(false);
                }

                // เรียก API เพื่อข้อมูลรูปภาพทั้งหมด
                const imgRequests = [1, 2, 3].map(seq =>
                    Axios.post(`${process.env.REACT_APP_API_URL}/api/get-user-img`, {
                        user_id: userId,
                        img_seq: seq
                    }, {
                        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
                    })
                );

                const imgResponses = await Promise.all(imgRequests);
                const imgList = imgResponses.map(res => res.data);

                setUserImgList(imgList);
                if (imgList.length > 0) setSelectedImg(imgList[0]);
            } catch (error) {
                createAlert("FAILED", error.message);
            }
        };

        initialize();
    }, [userId]);

    const manageInterest = async (localUserId) => {
        setRenderIgnore(false);
        setRenderInterest(true);
        setRenderMatch(false);
        setRenderRemove(false);
        setRenderSubscribe(false);

        // ตรวจสอบ interest และ ignore status
        const [manageInterest, manageInterested, checkMatching] = await Promise.all([
            Axios.post(`${process.env.REACT_APP_API_URL}/api/manage-interest`, {
                user_id: localUserId,
                user_interest_id: userId,
                action: 'check'
            }, {
                headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
            }),
            Axios.post(`${process.env.REACT_APP_API_URL}/api/manage-interest`, {
                user_id: userId,
                user_interest_id: localUserId,
                action: 'check'
            }, {
                headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
            }),
            Axios.post(`${process.env.REACT_APP_API_URL}/api/manage-interest`, {
                user_id: userId,
                user_interest_id: localUserId,
                action: 'check-response'
            }, {
                headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
            })
        ]);

        if (manageInterest.data.result === 1) {
            setRenderIgnore(true);
            setRenderInterest(false);
            setRenderMatch(false);
            setRenderRemove(false);
            setRenderSubscribe(false);
        }

        if (manageInterested.data.result === 1) {
            setRenderIgnore(false);
            setRenderInterest(false);
            setRenderMatch(true);
            setRenderRemove(true);
            setRenderSubscribe(false);
        }

        if (checkMatching.data.result === 1) {
            setRenderIgnore(false);
            setRenderInterest(false);
            setRenderMatch(false);
            setRenderRemove(true);
            setRenderSubscribe(true);
        }
    };

    const renderSmallImage = (img, index) => (
        <div key={index} className="small-image-card" style={{ cursor: "pointer" }} onClick={() => setSelectedImg(img)}>
            {img.status === "FAILED" ? (
                <div className="small-image">{/* Empty */}</div>
            ) : (
                <img src={img.image} alt={`Small ${index + 1}`} className="small-image" />
            )}
        </div>
    );

    const handleInterest = async () => {
        const confirmed = window.confirm("Are you sure you want to show interest in this him/her?");
        if (confirmed) {
            try {
                const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/create-interest`, {
                    user_id: localStorage.getItem('user_id'),
                    user_interest_id: userId,
                    interest_date: new Date(),
                    active_flag: "Y",
                    create_user: "User",
                    create_datetime: new Date(),
                    create_process: window.location.pathname
                }, {
                    headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
                });

                if (response.data.status === 'OK') {
                    await manageInterest(localStorage.getItem('user_id'));
                    createAlert("OK", "Interest Finished");
                }
            } catch (error) {
                createAlert("FAILED", error.message);
            }
        }
    };

    const handleIgnore = async () => {
        const confirmed = window.confirm("Are you sure you want to ignore in this him/her?");
        if (confirmed) {
            try {
                const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/manage-interest`, {
                    user_id: localStorage.getItem('user_id'),
                    user_interest_id: userId,
                    action: 'ignore'
                }, {
                    headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
                });

                if (response.data.status === 'OK') {
                    await manageInterest(localStorage.getItem('user_id'));
                    createAlert("OK", "Interest Finished");
                }
            } catch (error) {
                createAlert("FAILED", error.message);
            }
        }
    };

    const handleRemove = async () => {
        const confirmed = window.confirm("Are you sure you want to ignore in this him/her?");
        if (confirmed) {
            try {
                const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/manage-interest`, {
                    user_id: userId,
                    user_interest_id: localStorage.getItem('user_id'),
                    action: 'ignore'
                }, {
                    headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
                });

                if (response.data.status === 'OK') {
                    await manageInterest(localStorage.getItem('user_id'));
                    createAlert("OK", "Interest Finished");
                }
            } catch (error) {
                createAlert("FAILED", error.message);
            }
        }
    };

    const handleMatch = async () => {
        const confirmed = window.confirm("Are you sure you want to match with him/her?");
        if (confirmed) {
            try {
                const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/manage-interest`, {
                    user_id: userId,
                    user_interest_id: localStorage.getItem('user_id'),
                    action: 'match'
                }, {
                    headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
                });

                if (response.data.status === 'OK') {
                    await manageInterest(localStorage.getItem('user_id'));
                    createAlert("OK", "Interest Finished");
                }
            } catch (error) {
                createAlert("FAILED", error.message);
            }
        }
    };

    const updateStatusActiveFlag = async (status) => {
        let isConfirmed = false;

        if (status === 'show') {
            isConfirmed = window.confirm("Are you sure you want to show profile?");
        } else if (status === 'hide') {
            isConfirmed = window.confirm("Are you sure you want to hide profile?");
        }

        if (!isConfirmed) {
            return;
        }

        try {
            const updateResponse = async (table) => {
                return await updateStatusActive(table, userId, status === 'show' ? "Y" : "N", "Admin", window.location.pathname);
            }

            await Promise.all([
                updateResponse("USER_INFO"),
                updateResponse("PERSON_INFO"),
                updateResponse("FAVORITE_INFO"),
                updateResponse("DATE_INFO")
            ]);

            window.location.reload();
        } catch (error) {
            createAlert("FAILED", error.message);
        }
    };

    const { fullname, age, gender, birthdate, country, caption, occupation } = userData;

    return (
        <>
            <div id="section-1" style={{ padding: "1rem" }}>
                <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4">
                        <div className="profile-image-container">
                            {selectedImg && (
                                <img src={selectedImg.image} alt="Profile" className="profile-image-1" />
                            )}
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-8">
                        <div className="row">
                            <div className="col-12">
                                <div className="small-images-container">
                                    {userImgList.slice(0, 3).map(renderSmallImage)}
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-12 col-lg-6">
                                        <h3 style={{ color: 'red' }}>
                                            {hideProfile
                                                ? '[Hidden]'
                                                : (
                                                    <>
                                                        {`${fullname}, ${age} (${gender})`}
                                                        {isOwnerHidden && <span style={{ color: 'black' }}> [Hidden]</span>}
                                                    </>
                                                )}
                                        </h3>
                                    </div>
                                    <div className="col-12 col-lg-6 text-lg-end">
                                        {isOwnerProfile ? (
                                            <>
                                                <button
                                                    onClick={() => updateStatusActiveFlag('hide')}
                                                    className="btn btn-warning"
                                                    style={{ marginRight: '20px' }}
                                                    hidden={isOwnerHidden ? true : false}>
                                                    Hide Profile
                                                </button>
                                                <button
                                                    onClick={() => updateStatusActiveFlag('show')}
                                                    className="btn btn-info"
                                                    style={{ marginRight: '20px' }}
                                                    hidden={isOwnerHidden ? false : true}>
                                                    Show Profile
                                                </button>
                                                <button onClick={() => handleEditSection("1")} className="btn btn-danger w-20 w-lg-auto">
                                                    Edit
                                                </button>
                                            </>
                                        ) : (
                                            <>

                                                {renderInterest && (
                                                    <button
                                                        onClick={handleInterest}
                                                        className={`btn btn-danger w-20 w-lg-auto me-2`}
                                                    >
                                                        Interest
                                                    </button>
                                                )}
                                                {renderMatch && (
                                                    <button
                                                        onClick={handleMatch}
                                                        className={`btn btn-danger w-20 w-lg-auto me-2`}
                                                    >
                                                        Match
                                                    </button>
                                                )}
                                                {!isMember && renderSubscribe && (
                                                    <button
                                                        onClick={() => window.location.replace(`/my-package`)}
                                                        className={`btn btn-info w-20 w-lg-auto me-2`}
                                                    >
                                                        Subscribe
                                                    </button>
                                                )}
                                                {renderIgnore && (
                                                    <button
                                                        onClick={handleIgnore}
                                                        className={`btn btn-secondary w-20 w-lg-auto me-2`}
                                                    >
                                                        Ignore
                                                    </button>
                                                )}
                                                {renderRemove && (
                                                    <button
                                                        onClick={handleRemove}
                                                        className={`btn btn-secondary w-20 w-lg-auto me-2`}
                                                    >
                                                        Unmatch
                                                    </button>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="row" style={{ padding: "1rem" }}>
                                    <div className="col-4">
                                        <b id="h6-header">Birthday</b>
                                        <p id="p-detail">{hideProfile ? '[Hidden]' : birthdate}</p>
                                    </div>
                                    <div className="col-4">
                                        <b id="h6-header">Country</b>
                                        <p id="p-detail">{hideProfile ? '[Hidden]' : country}</p>
                                    </div>
                                    <div className="col-4">
                                        <b id="h6-header">Occupation</b>
                                        <p id="p-detail">{hideProfile ? '[Hidden]' : occupation}</p>
                                    </div>
                                </div>

                                <div className="row" style={{ padding: "1rem" }}>
                                    <div className="col-12">
                                        <b id="h6-header">Caption</b>
                                        <p id="p-detail">{hideProfile ? '[Hidden]' : caption}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isMember && isMatching && !isOwnerProfile && (
                <>
                    <hr />
                    <div style={{ padding: "1rem", backgroundColor: '#FAE8DE' }}>
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6">
                                <h5 style={{ color: 'red' }}>
                                    Contact
                                </h5>
                            </div>
                        </div>
                        <div className="row"
                            style={{ padding: "1rem" }}>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <b id="h6-header">Email</b>
                                <p id="p-detail">{userData.email}</p>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <b id="h6-header">Line</b>
                                <p id="p-detail">{userData.line}</p>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <b id="h6-header">What's App</b>
                                <p id="p-detail">{userData.whatsApp}</p>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <b id="h6-header">Facebook</b>
                                <br />
                                <a href={userData.facebook} target='_blank' id="p-detail">{userData.facebook}</a>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <b id="h6-header">Phone</b>
                                <p id="p-detail">{userData.phone}</p>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Section1;