import React from 'react';

const Steps = ({ mode, sectionIndex }) => {
    return (
        <div hidden={mode === 'EDIT'}>
            {sectionIndex !== 0 && (
                <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                    <div className={`step ${sectionIndex >= 1 ? 'completed' : ''}`}>
                        <div className="step-icon-wrap">
                            <div className="step-icon"><i className="fa fa-user"></i></div>
                        </div>
                        <h4 className="step-title">User Information</h4>
                    </div>
                    <div className={`step ${sectionIndex >= 2 ? 'completed' : ''}`}>
                        <div className="step-icon-wrap">
                            <div className="step-icon"><i className="fa fa-id-card"></i></div>
                        </div>
                        <h4 className="step-title">Personal Information</h4>
                    </div>
                    <div className={`step ${sectionIndex >= 3 ? 'completed' : ''}`}>
                        <div className="step-icon-wrap">
                            <div className="step-icon"><i className="fa fa-heart"></i></div>
                        </div>
                        <h4 className="step-title">Favorite</h4>
                    </div>
                    <div className={`step ${sectionIndex >= 4 ? 'completed' : ''}`}>
                        <div className="step-icon-wrap">
                            <div className="step-icon"><i className="fa fa-users"></i></div>
                        </div>
                        <h4 className="step-title">Date Information</h4>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Steps;