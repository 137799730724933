import React, { useEffect, useState } from 'react';
import { FaCamera } from 'react-icons/fa';
import Axios from 'axios';
import { createAlert } from 'utils/MainUtils';

import '../../assets/css/components/payment.css';

// import kasikornBank from "assets/images/kasikorn-bank.png";
// import bangkokBank from "assets/images/bangkok-bank.png";
import qrCode from 'assets/images/qr-code.jpg';

function Payment({ purchaseId, packageId }) {
  const [packageList, setPackageList] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);

  useEffect(() => {
    initialize();
  }, [purchaseId, packageId]);

  const initialize = async () => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/get-package-by-id`,
        { package_id: packageId },
        { headers: { key: process.env.REACT_APP_ANALYTICS_KEY } }
      );

      if (Array.isArray(response.data)) {
        setPackageList(response.data);
      } else {
        setPackageList([]);
      }
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setImagePreview(reader.result); // Update state with image data
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemovePhoto = async () => {
    setImagePreview(null); // Clear selected image
  };

  const handleIconClick = () => {
    document.getElementById('payment-img-file').click();
  };

  const handleSavePurchase = async () => {
    setShowSaveModal(true); // Show save modal
  };

  const handleConfirmSave = async () => {
    try {
      setShowSaveModal(false);

      const imgFile = document.getElementById('payment-img-file').files[0];

      if (!imgFile) return;

      const formData = new FormData();
      formData.append('image', imgFile);

      const uploadResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/purchase/upload`, formData, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (uploadResponse.data.status !== 'OK') {
        return createAlert(uploadResponse.data.status, uploadResponse.data.message);
      }

      const purchaseData = {
        purchase_receipt: new Date(),
        purchase_img_file: uploadResponse.data.fileId,
        purchase_img_type: uploadResponse.data.contentType,
        purchase_img_size: uploadResponse.data.fileSize,
        purchase_status: 'WA',
        update_user: 'User',
        update_datetime: new Date(),
        update_process: window.location.pathname,
        purchase_id: purchaseId
      };

      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/update-purchase-order`, purchaseData, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (response.data.status === 'OK') {
        window.location.reload();
      } else {
        return createAlert(response.data.status, response.data.message);
      }
    } catch (error) {
      createAlert("FAILED", error.message);
    }
  };

  const handleCloseSaveModal = () => {
    setShowSaveModal(false);
  };

  const handleRemovePurchase = async () => {
    setShowModal(true); // Show remove modal
  };

  const handleConfirmRemove = async () => {
    try {
      let data = {
        table: 'PURCHASE_ORDER',
        package_id: packageId,
        user_id: localStorage.getItem('user_id')
      };

      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/delete-by-package-id`, data, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (response.data.status === 'OK') {
        window.location.reload();
      } else {
        createAlert(response.data.status, response.data.message);
      }
    } catch (error) {
      createAlert('FAILED', error.message);
    } finally {
      setShowModal(false); // Close modal after action
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="Payment">
      <div class="container">
        <center>
          <div class="row">
            <div class="col-sm-6">
              <div class="col-8 col-sm-6">
                <h4 className="fw-semibold" style={{ color: 'red' }}>Select Package</h4>
                {Array.isArray(packageList) && packageList.length > 0 ? (
                  packageList.map((item) => (
                    <div key={item.package_id} className="card mb-3 mx-auto" style={{ width: "20rem" }}>
                      <div className="card-body mt-3">
                        <h5 className="card-title text-danger">{item.name}</h5>
                        <h2 className="card-text mt-3 text-danger">{item.price} Baht</h2>
                        {item.detail ? (
                          <p className="mt-3 text-white bg-danger">{item.detail}</p>
                        ) : (
                          <p className="mt-3 text-white">&nbsp;</p>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No packages available</p>
                )}
              </div>
              <div class="col-8 col-sm-6" />
              <div class="col-8 col-sm-6">
                <div className="row mt-5">
                  <div className="col-12">
                    <h4 className="fw-semibold" style={{ color: 'red' }}>Payment</h4>
                  </div>
                  <div className="col-12 mt-2">
                    <span className="text-secondary">Transfer to promtpay no : xxx-xxx-9194</span>
                    <p className="fw-semibold" style={{ color: 'red' }}>
                      <span style={{ color: 'red' }}>Account Name: น.ส. ภภัฐ บุญเย็น</span>
                    </p>
                  </div>
                  {/* <div className="col-12 mt-2">
                    <p className="fw-semibold" style={{ color: 'red' }}>
                      <span style={{ color: 'red' }}>Account Name: Thai Norway</span>
                    </p>
                  </div>
                  <div className="col-12">
                    <p className="fw-semibold" style={{ color: 'red' }}>
                      <img src={kasikornBank} width={35} height={35} style={{ marginRight: '15px' }} />
                      <span style={{ color: 'gray', opacity: '80%' }}>Kasikorn Banking 999-9-00000-0</span>
                    </p>
                  </div>
                  <div className="col-12">
                    <p className="fw-semibold" style={{ color: 'red' }}>
                      <img src={bangkokBank} width={35} height={35} style={{ marginRight: '15px' }} />
                      <span style={{ color: 'gray', opacity: '80%' }}>Bangkok Banking 999-9-00000-0</span>
                    </p>
                  </div> */}
                  <div className="col-12 mt-2">
                    <img src={qrCode}></img>
                  </div>
                </div>
              </div>
            </div>
            <br /><br />
            <div class="col-sm-6">
              <div class="col-8 col-sm-6">
                <h4 className="fw-semibold" style={{ color: 'red', textAlign: 'center' }}>Attach Receipt</h4>
                <br />
                <div className="form-group">
                  {/* Image input */}
                  <input
                    className="form-control form-control-lg mt-4 d-none"
                    id="payment-img-file"
                    type="file"
                    name="payment-img-file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                  {/* Image preview */}
                  {imagePreview && (
                    <div className="mt-3">
                      <div className="circular-preview mx-auto mb-3">
                        <img src={imagePreview} alt="Preview" className="img-fluid" />
                      </div>
                      <br />
                      <a onClick={handleRemovePhoto} style={{ cursor: 'pointer' }} className="text-decoration-underline text-dark">Remove Photo</a>
                    </div>
                  )}
                  {/* Icon for file selection */}
                  {!imagePreview && (
                    <FaCamera
                      style={{ fontSize: "50px", cursor: 'pointer' }}
                      onClick={handleIconClick}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            {imagePreview ? (
              <>
                <div className="col-12 col-md-12">
                  <div className="text-center mt-3">
                    <button
                      onClick={handleSavePurchase}
                      className="btn btn-md btn-info text-white"
                      style={{ width: '30%', marginRight: '50px' }}>
                      Save
                    </button>
                    <button
                      onClick={handleRemovePurchase}
                      className="btn btn-md btn-danger"
                      style={{ width: '30%' }}>
                      Remove
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div className="col-12 col-md-12">
                <div className="text-center mt-3">
                  <button
                    onClick={handleRemovePurchase}
                    className="btn btn-md btn-danger"
                    style={{ width: '20%' }}>
                    Remove
                  </button>
                </div>
              </div>
            )}
          </div>
        </center>
        {showModal && (
          <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirm Removal</h5>
                  <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  Are you sure you want to remove this purchase?
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Cancel</button>
                  <button type="button" className="btn btn-danger" onClick={handleConfirmRemove}>Remove</button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showSaveModal && (
          <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirm Save</h5>
                  <button type="button" className="btn-close" onClick={handleCloseSaveModal} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  Are you sure you want to save this purchase?
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={handleCloseSaveModal}>Cancel</button>
                  <button type="button" className="btn btn-info" onClick={handleConfirmSave}>Save</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Payment;
