import React from 'react';
import AbstractToggle from 'components/Toggle/AbstractToggle';

function FormSection4({
    insertDateInfo,
    lookingFor, setLookingFor,
    genderFor, setGenderFor,
    ageStart, setAgeStart,
    ageEnd, setAgeEnd,
    usedCommu, setUsedCommu,
    learningThai, setLearningThai,
    thSpouseReason, setThSpouseReason,
    thWomenThink, setThWomenThink,
    spouseExpect, setSpouseExpect,
    country, gender
}) {

    // สร้าง array ของอายุเพื่อใช้ใน options
    const ageOptions = Array.from({ length: 82 }, (_, i) => 18 + i);

    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group mt-3 text-center">
                        <h2>Date Information</h2>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group mt-3">
                        <label id="at-lookingFor" className="required-label">What are you looking for?</label>
                        <AbstractToggle rf={"LOOKING_FOR"} value={lookingFor} setValue={setLookingFor} disabled={false} multiple={false} />
                    </div>
                    <div className="form-group mt-3">
                        <label id="at-genderFor" className="required-label">Gender looking for?</label>
                        <AbstractToggle rf={"GENDER"} value={genderFor} setValue={setGenderFor} disabled={false} multiple={false} />
                    </div>
                    <div className="row">
                        <label id="ip-range-age">Age range of interest</label>
                        <div className="col-6 col-sm-6 col-md-4 col-lg-4">
                            <div className="form-group mt-3">
                                <select
                                    className="form-control form-control-lg"
                                    id="ageStart"
                                    name="ageStart"
                                    value={ageStart}
                                    onChange={(e) => setAgeStart(Number(e.target.value))}
                                >
                                    {ageOptions.map((age) => (
                                        <option key={age} value={age}>
                                            {age}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-4 col-lg-4">
                            <div className="form-group mt-3">
                                <select
                                    className="form-control form-control-lg"
                                    id="ageEnd"
                                    name="ageEnd"
                                    value={ageEnd}
                                    onChange={(e) => setAgeEnd(Number(e.target.value))}
                                >
                                    {ageOptions
                                        .filter((age) => age >= ageStart)
                                        .map((age) => (
                                            <option key={age} value={age}>
                                                {age}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <label id="at-usedCommu" className="required-label">Do you think the language used in Communication is important?</label>
                        <AbstractToggle rf={"ACTIVE_FLAG"} value={usedCommu} setValue={setUsedCommu} disabled={false} multiple={false} />
                    </div>
                    <div className="form-group mt-3">
                        <label id="at-learningThai" className="required-label">Are you interested in learning Thai?</label>
                        <AbstractToggle rf={"ACTIVE_FLAG"} value={learningThai} setValue={setLearningThai} disabled={false} multiple={false} />
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group mt-3">
                        <label
                            id="ta-thSpouseReason"
                            className={gender === 'M' && country !== 'Thailand' ? 'required-label' : ''}>
                            Why do you want to have a Thai spouse?
                        </label>
                        <textarea
                            className="form-control form-control-lg"
                            id="thSpouseReason"
                            name="thSpouseReason"
                            rows="4"
                            cols="50"
                            value={thSpouseReason}
                            required={gender === 'M' && country !== 'Thailand' ? true : false}
                            onChange={(e) => setThSpouseReason(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label
                            id="ta-thWomenThink"
                            className={gender === 'M' && country !== 'Thailand' ? 'required-label' : ''}>
                            What do you think about Thai women/man?
                        </label>
                        <textarea
                            className="form-control form-control-lg"
                            id="thWomenThink"
                            name="thWomenThink"
                            rows="4"
                            cols="50"
                            value={thWomenThink}
                            required={gender === 'M' && country !== 'Thailand' ? true : false}
                            onChange={(e) => setThWomenThink(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label id="ta-spouseExpect">What do you expect from your future spouse when you marry him/her?</label>
                        <textarea
                            className="form-control form-control-lg"
                            id="spouseExpect"
                            name="spouseExpect"
                            rows="4"
                            cols="50"
                            value={spouseExpect}
                            onChange={(e) => setSpouseExpect(e.target.value)}
                        />
                    </div>
                </div>

                {/* Modal สำหรับ Confirm Dialog */}
                <button id="confirm-finished-btn" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#finishedModal" hidden />
                <div className="modal fade" id="finishedModal" tabIndex="-1" aria-labelledby="finishedModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="finishedModalLabel">Confirm Dialog</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {`Are you sure you want to confirm the data?`}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => insertDateInfo()}>Confirm</button>
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FormSection4;