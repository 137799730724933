import '../../../assets/css/login.css';
import Axios from "axios";
import React, { useState } from 'react';
import Header from "components/Headers/Header";
import Footer from "components/Footers/Footer";
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // นำเข้าไอคอนจาก react-icons

// Utils
import { createAlert } from 'utils/MainUtils';

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onChangeInput = (id) => {
    if (document.getElementById(id).value === '') {
      document.getElementById(id).addEventListener('change', function (event) {
        document.getElementById(id).value = event.target.value;
      });
    }
  }

  const handleLogin = async () => {
    try {
      const username = document.getElementById('login_username').value;
      const password = document.getElementById('login_password').value;

      if (username === "" || password === "") {
        createAlert("FAILED", "Please fill in Username or Password");
      } else {
        const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/user/login`,
          {
            username,
            password
          },
          {
            headers: {
              key: process.env.REACT_APP_ANALYTICS_KEY,
            }
          }
        );

        if (response.data.status === 'OK') {
          const res = await Axios.get(`${process.env.REACT_APP_API_URL}/api/get-user-by-username/${username}`, {
            headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
          });
          if (res.data.status === 'FAILED') {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            window.location.replace("/login");
          } else {
            localStorage.setItem('user_id', res.data.user[0].user_id);
          }
          localStorage.setItem('token', response.data.token);
          window.location.replace("/");
        } else {
          localStorage.removeItem('token');
          localStorage.removeItem('user_id');
          createAlert(response.data.status, response.data.message);
        }
      }
    } catch (error) {
      createAlert("FAILED", error.message);
    }
  };

  const handleForgotPassword = async () => {
    try {
      const email = document.getElementById('forgot_email').value;

      if (email === "") {
        createAlert("FAILED", "Please fill in your Email");
      } else {
        const confirm = window.confirm("Are you sure you want to submit the request to reset your password?");

        if (confirm) {
          const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/update-change-password`,
            {
              email: email,
              status: 'Y'
            },
            {
              headers: {
                key: process.env.REACT_APP_ANALYTICS_KEY,
              }
            }
          );

          if (response.data.status === 'OK') {
            setIsForgotPassword(false);
            createAlert("OK", "Your request to reset the password has been successfully submitted to the administrator.");
          } else {
            createAlert(response.data.status, response.data.message);
          }
        }
      }
    } catch (error) {
      createAlert("FAILED", error.message);
    }
  };

  return (
    <div className="Login" style={{ height: "100vh" }}>
      <Header />
      <div className="content" style={{ height: "calc(100vh - 100px)", backgroundColor: "#fae8de" }}>
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="card">
                  <div className="card-body">
                    <div className="m-sm-4">
                      {isForgotPassword ? (
                        <>
                          <h1 style={{ textAlign: 'center' }}>Reset your password</h1>
                          <p style={{ textAlign: 'center', fontSize: 'small' }}>
                            Enter your email address for the administrator. We'll approve your request to change your password.
                          </p>
                          <div className="form-group mt-3">
                            <label>Email</label>
                            <input
                              className="form-control form-control-lg"
                              id="forgot_email"
                              type="email"
                              name="forgot_email"
                              onChange={() => onChangeInput('forgot_email')} />
                          </div>
                          <div className="text-center mt-3">
                            <button className="btn btn-lg btn-danger" onClick={() => handleForgotPassword()}>Submit</button>
                          </div>
                        </>
                      ) : (
                        <>
                          <h2 style={{ textAlign: 'center' }}>LOGIN</h2>
                          <div className="form-group mt-3">
                            <label>Username</label>
                            <input className="form-control form-control-lg" id="login_username" type="text" name="login_username"
                              onChange={() => onChangeInput('login_username')} />
                          </div>
                          <div className="form-group mt-3">
                            <label>Password</label>
                            <div className="input-group">
                              <input className="form-control form-control-lg" id="login_password" type={showPassword ? "text" : "password"} name="login_password"
                                onChange={() => onChangeInput('login_password')} />
                              <span className="input-group-text" onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                              </span>
                            </div>
                            <div className="text-right mt-2" style={{ textAlign: 'right' }}>
                              <a href="#" onClick={() => setIsForgotPassword(true)} style={{ textDecoration: 'underline', color: 'black' }}>Forgot password</a>
                            </div>
                          </div>
                          <div className="text-center mt-3">
                            <button className="btn btn-lg btn-danger" onClick={() => handleLogin()}>Login</button>
                          </div>
                          <br />
                          <div className="text-right mt-2" style={{ textAlign: 'center' }}>
                            <span>Have an Account? <a href="/register" style={{ color: 'blue' }}>Register</a></span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer isPadding={false} />
    </div>
  );
}

export default Login;