import React, { useState } from 'react';
import '../../../assets/css/contact.css';
import Header from "components/Headers/Header";
import Footer from "components/Footers/Footer";
import image from "assets/images/logo.png";

function Contact() {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleConfirm = () => {
    setShowModal(false);
    window.location.href = "mailto:thainorwaydate@gmail.com";
  };

  return (
    <div className="Contact">
      <Header />
      <div className="container mt-5">
        <div className="text-center mb-5">
          <h2 className="display-18 display-md-16 display-lg-14 font-weight-700">Contact Us</h2>
          <p className="text-secondary" style={{ fontSize: '24px' }}>
            Email : <a href="#!" onClick={handleShowModal} style={{ color: 'green' }}>thainorwaydate@gmail.com</a>
          </p>
        </div>
        <div className="row align-items-center">
          <div className="col-12 text-center">
            <div className="why-choose-center-image">
              <img src={image} alt="Logo" className="rounded" />
            </div>
          </div>
        </div>
      </div>
      <Footer isPadding={true} />

      {/* Modal */}
      <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Email</h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={handleCloseModal}></button>
            </div>
            <div className="modal-body">
              <p>Do you want to send an email to thainorwaydate@gmail.com?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Cancel</button>
              <button type="button" className="btn btn-danger" onClick={handleConfirm}>Confirm</button>
            </div>
          </div>
        </div>
      </div>
      {showModal && <div className="modal-backdrop fade show"></div>}
    </div>
  );
}

export default Contact;