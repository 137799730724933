import AbstractToggle from 'components/Toggle/AbstractToggle';

function FormSection3({
    drinking, setDrinking,
    favorite, setFavorite,
    sexualOrien, setSexualOrien,
    freeTime, setFreeTime,
    politics, setPolitics,
    smoking, setSmoking,
    favoriteFood, setFavoriteFood,
    favoriteSport, setFavoriteSport,
    community, setCommunity
}) {

    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group mt-3 text-center">
                        <h2>Favorite</h2>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group mt-3">
                        <label id="at-drinking" className="required-label">How often do you drink?</label>
                        <AbstractToggle rf={"DRINKING"} value={drinking} setValue={setDrinking} disabled={false} multiple={false} />
                    </div>
                    <div className="form-group mt-3">
                        <label id="at-like">What do you like? <span style={{ color: "red" }}>(Can choose more than 1)</span></label>
                        <AbstractToggle rf={"FAVORITE"} value={favorite} setValue={setFavorite} disabled={false} multiple={true} />
                    </div>
                    <div className="form-group mt-3">
                        <label id="at-sexualOrien">What is your sexual orientation?</label>
                        <AbstractToggle rf={"SEXUAL_ORIEN"} value={sexualOrien} setValue={setSexualOrien} disabled={false} multiple={false} />
                    </div>
                    <div className="form-group mt-3">
                        <label id="ip-freeTime">What do you like to do in your free time?</label>
                        <textarea
                            className="form-control form-control-lg"
                            id="freeTime"
                            name="freeTime"
                            rows="4"
                            cols="50"
                            value={freeTime}
                            onChange={(e) => setFreeTime(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label id="at-politics">Politics</label>
                        <AbstractToggle rf={"POLITICS"} value={politics} setValue={setPolitics} disabled={false} multiple={false} />
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group mt-3">
                        <label id="at-smoking" className="required-label">Do you Smoking?</label>
                        <AbstractToggle rf={"SMOKING"} value={smoking} setValue={setSmoking} disabled={false} multiple={false} />
                    </div>
                    <div className="form-group mt-3">
                        <label id="at-favoriteFood">Favorite Food <span style={{ color: "red" }}>(Can choose more than 1)</span></label>
                        <AbstractToggle rf={"FOOD"} value={favoriteFood} setValue={setFavoriteFood} disabled={false} multiple={true} />
                    </div>
                    <div className="form-group mt-3">
                        <label id="at-favoriteSport">Favorite Sport <span style={{ color: "red" }}>(Can choose more than 1)</span></label>
                        <AbstractToggle rf={"SPORT"} value={favoriteSport} setValue={setFavoriteSport} disabled={false} multiple={true} />
                    </div>
                    <div className="form-group mt-3">
                        <label id="at-community">Causes and communites <span style={{ color: "red" }}>(Can choose more than 1)</span></label>
                        <AbstractToggle rf={"COMMUNITY"} value={community} setValue={setCommunity} disabled={false} multiple={true} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default FormSection3;
