import React from 'react';
import Axios from 'axios';

function Footer({ isPadding }) {
  const [version, setVersion] = React.useState();

  React.useEffect(() => {
    // Function to fetch data from the API
    const fetchVersion = async () => {
      try {
        const response = await Axios.get(`${process.env.REACT_APP_API_URL}/`);
        setVersion(response.data.message);
      } catch (error) {
        console.error('Error fetching the version:', error);
      }
    };

    fetchVersion();
  }, []);

  return (
    <>
      {isPadding && <section id="points" className="padding-medium pt-0" />}

      <div id="footer-bottom" style={{ backgroundColor: "#F47D65", color: "white", marginTop: "-20px" }}>
        <hr className="text-black-50" />
        <div className="container">
          <div className="row py-3">
            <div className="col-sm-12 col-md-12 col-lg-4 text-md-start">
              <p>
                <a href="#" style={{ color: "white", textDecoration: "none" }}><b>Terms & Conditions</b></a>&emsp;
                <a href="#" style={{ color: "white", textDecoration: "none" }}><b>Privacy Policy</b></a>&emsp;
                <a href="#" style={{ color: "white", textDecoration: "none" }}><b>Dating Securely</b></a>
              </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4 text-center">
              {/* do nothing */}
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4 text-md-end">
              {version && <a href="/update" style={{ color: "white", textDecoration: "none" }}><b>Version {version}</b></a>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;