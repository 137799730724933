import '../../../assets/css/about.css';
import Header from "components/Headers/Header";
import Footer from "components/Footers/Footer";
import React, { useEffect, useState } from 'react';
import Axios from 'axios';

function About() {
  const [imagePreview, setImagePreview] = useState(null);
  const [language, setLanguage] = useState('en'); // ตั้งค่าภาษาเริ่มต้น

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const image = await getSettingImg('about');
      setImagePreview(image);
    } catch (error) {
      createAlert('FAILED', error.message);
    }
  };

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    // คุณสามารถเพิ่มโค้ดสำหรับการเปลี่ยนภาษาเพิ่มเติมที่นี่
  };

  const getSettingImg = async (type) => {
    const imgResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-setting-img`, {
      type: type
    }, {
      headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
    });
    return imgResponse.data.image;
  };

  return (
    <div className="About">
      <Header />
      <div className="container my-5">
        <div className="row align-items-center">
          <div className="col-md-1" />
          <div className="col-md-5">
            <img src={imagePreview} alt="About" className="img-fluid rounded-circle mb-4 mb-md-0" />
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-left">
              <img
                src="images/thai-flag.png"
                alt="Thai Flag"
                className={`img-fluid me-2 language-flag ${language === 'th' ? 'active' : ''}`}
                onClick={() => handleLanguageChange('th')}
              />
              <img
                src="images/english-flag.png"
                alt="English Flag"
                className={`img-fluid me-2 language-flag ${language === 'en' ? 'active' : ''}`}
                onClick={() => handleLanguageChange('en')}
              />
              <img
                src="images/norway-flag.png"
                alt="Norway Flag"
                className={`img-fluid language-flag ${language === 'no' ? 'active' : ''}`}
                onClick={() => handleLanguageChange('no')}
              />
            </div>
            <br />
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="display-8 fw-semibold">
                {language === 'en' ? 'Welcome to the Thainorway Dating Website' :
                  language === 'th' ? 'ยินดีต้อนรับสู่เว็บไซต์หาคู่ไทยนอร์เวย์' :
                    'Velkommen til det thailandsk-norske datingnettstedet!'}
              </h3>
            </div>
            <p>
              {/* เนื้อหาของข้อความจะแสดงตามภาษาที่เลือก */}
              {language === 'en' ? (
                <>
                  Through over 14 years of volunteer work and assistance for Thais in Norway, we have observed an increasing
                  number of divorce disputes each year. While cases without children are relatively straightforward, those
                  involving children present complex issues concerning custody and other matters. These conflicts often result
                  in significant stress for both Thai women and Norwegian men.
                </>
              ) : language === 'th' ? (
                <>
                  ผ่านประสบการณ์การทำงานอาสาสมัครกว่า 14 ปี และการให้ความช่วยเหลือคนไทยในนอร์เวย์ เราสังเกตเห็นว่ามีข้อพิพาทการหย่าร้างเพิ่มขึ้นทุกปี
                  กรณีที่ไม่มีบุตรจะค่อนข้างง่าย แต่กรณีที่มีบุตรจะมีปัญหาซับซ้อนเกี่ยวกับการดูแลบุตรและเรื่องอื่น ๆ
                  ข้อพิพาทเหล่านี้มักสร้างความเครียดให้กับทั้งหญิงไทยและชายชาวนอร์เวย์
                </>
              ) : (
                <>
                  Har jobbet 14 år med frivillig arbeid og hjelp til thailendere i Norge. Vi har merket en
                  økning i skilsmisser hvert år. Tilfeller uten barn er relativt enkle, men med barn involvert
                  blir det ofte komplekse spørsmål om barneomsorg og andre forhold. Disse konfliktene
                  skaper ofte stress for både thailandske kvinner og norske menn.
                </>
              )}
            </p>
            <h5 className="fw-bold mt-4">
              {language === 'en' ? 'Our objectives are as follows:' :
                language === 'th' ? 'วัตถุประสงค์ของเรามีดังนี้:' :
                  'Våre mål er som følger:'}
            </h5>
            <ul className="list-unstyled">
              {language === 'en' ? (
                <>
                  <li className="mb-3">1. To reduce potential future conflicts between Thai women and Norwegian men.</li>
                  <li className="mb-3">
                    2. To foster mutual understanding by educating both parties on the customs and traditions
                    of Thailand and Norway. This ensures that both parties comprehend and respect each other’s cultural
                    backgrounds before deciding to marry.
                  </li>
                  <li className="mb-3">
                    3. To emphasize the importance of honesty and integrity for both parties, both publicly
                    and privately. Information provided should be accurate and truthful. In the event of future issues,
                    these records will serve as evidence to protect both parties from any unfair treatment.
                  </li>
                  <li className="mb-3">
                    4. Any disputes or issues between you and your date are outside the responsibility of
                    the company. Our role is merely to provide a platform for you to meet each other.
                  </li>
                </>
              ) : language === 'th' ? (
                <>
                  <li className="mb-3">1. เพื่อลดความขัดแย้งที่อาจเกิดขึ้นในอนาคตระหว่างหญิงไทยกับชายชาวนอร์เวย์</li>
                  <li className="mb-3">
                    2. เพื่อส่งเสริมความเข้าใจซึ่งกันและกันโดยให้ความรู้ทั้งสองฝ่ายเกี่ยวกับประเพณีและวัฒนธรรมของไทยและนอร์เวย์
                    เพื่อให้ทั้งสองฝ่ายเข้าใจและเคารพพื้นฐานทางวัฒนธรรมของกันและกันก่อนที่จะตัดสินใจแต่งงาน
                  </li>
                  <li className="mb-3">
                    3. เพื่อเน้นความสำคัญของความซื่อสัตย์และความมีคุณธรรมสำหรับทั้งสองฝ่าย ทั้งในที่สาธารณะและในที่ส่วนตัว
                    ข้อมูลที่ให้ควรถูกต้องและเป็นจริง ในกรณีที่มีปัญหาในอนาคต ข้อมูลเหล่านี้จะใช้เป็นหลักฐานเพื่อปกป้องทั้งสองฝ่ายจากการถูกปฏิบัติที่ไม่เป็นธรรม
                  </li>
                  <li className="mb-3">
                    4. ปัญหาหรือข้อพิพาทใด ๆ ระหว่างคุณกับคู่เดทของคุณอยู่นอกเหนือความรับผิดชอบของบริษัท บทบาทของเราคือเพียงจัดหาแพลตฟอร์มให้คุณได้พบกัน
                  </li>
                </>
              ) : (
                <>
                  <li className="mb-3">1. Å redusere potensielle konflikter mellom thailandske kvinner og norske menn i
                    fremtiden.</li>
                  <li className="mb-3">
                    2. Å fremme gjensidig forståelse ved å informere begge parter om thailandske og norske
                    tradisjoner og kultur, slik at de kan forstå og respektere hverandres kulturelle bakgrunn før
                    de bestemmer seg for å gifte seg.
                  </li>
                  <li className="mb-3">
                    3. Å understreke viktigheten av ærlighet og moral for begge parter, både offentlig og
                    privat. Informasjonen som gis skal være korrekt og sannferdig. I tilfelle fremtidige
                    problemer vil denne informasjonen kunne brukes som bevis for å beskytte begge parter
                    mot urettferdig behandling.
                  </li>
                  <li className="mb-3">
                    4. Eventuelle problemer eller konflikter mellom deg og din partner er utenfor selskapets
                    ansvar. Vår rolle er kun å tilby en plattform der dere kan møtes.
                  </li>
                </>
              )}
            </ul>
            <p className="fw-bold">
              {language === 'en' ? (
                "This approach aims to prevent misunderstandings and promote harmonious relationships by ensuring that both parties are well-informed and transparent from the start."
              ) : language === 'th' ? (
                "แนวทางนี้มีวัตถุประสงค์เพื่อป้องกันความเข้าใจผิดและส่งเสริมความสัมพันธ์ที่กลมเกลียวโดยให้แน่ใจว่าทั้งสองฝ่ายได้รับข้อมูลที่ถูกต้องและโปร่งใสตั้งแต่ต้น"
              ) : (
                "Denne tilnærmingen har som mål å forhindre misforståelser og fremme harmoniske forhold ved å sikre at begge parter er godt informert og transparente fra starten."
              )}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;