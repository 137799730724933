import React, { useEffect, useState } from 'react';
import { getValue2Text, createAlert } from 'utils/MainUtils';

const PurchaseRow = ({ index, purchase }) => {
    const [statusText, setStatusText] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const text = await getValue2Text('PURCHASE_STATUS', purchase.purchase_status);
                setStatusText(text);
            } catch (error) {
                createAlert('FAILED', error);
                setStatusText('-');
            }
        };

        fetchData();
    }, [purchase.purchase_status]);

    return (
        <tr>
            <td>{index + 1}</td>
            <td>{purchase.purchase_amt}</td>
            <td>
                {new Date(purchase.purchase_date).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                })}
            </td>
            <td>{purchase.purchase_approve_date ? new Date(purchase.purchase_approve_date).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }) : 'N/A'}</td>
            <td>{purchase.purchase_receipt ? new Date(purchase.purchase_receipt).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }) : 'N/A'}</td>
            <td>{statusText}</td>
        </tr>
    );
};

export default PurchaseRow;