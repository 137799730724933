import Axios from "axios";
import React, { useEffect, useState } from 'react';
import { createAlert } from 'utils/MainUtils';

function AbstractToggle({ rf, value, setValue, disabled, multiple }) {
    const [refList, setRefList] = useState([]);

    useEffect(() => {
        const fetchDataList = async () => {
            try {
                const rfSubTable = rf;
                const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/load-rf/${rfSubTable}`, {
                    headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
                });

                if (response.data.status === 'OK') {
                    setRefList(response.data.result);
                } else {
                    createAlert("FAILED", response.data.message);
                }
            } catch (error) {
                createAlert("FAILED", error.message);
            }
        };

        fetchDataList();
    }, [rf]);

    const handleToggle = (selectedValue) => {
        if (multiple) {
            const selectedIndex = value.indexOf(selectedValue);
            let newValue = [...value];
            if (selectedIndex === -1) {
                newValue.push(selectedValue);
            } else {
                newValue.splice(selectedIndex, 1);
            }
            setValue(newValue);
        } else {
            setValue(selectedValue);
        }
    };

    return (
        <div className="option-toggle-buttons d-flex flex-wrap">
            {refList.map((item) => (
                <button
                    key={item.REFERENCE_CODE}
                    className={`btn ${value.includes(item.REFERENCE_CODE) ? 'btn-danger' : 'btn-outline-danger'}`}
                    type="button"
                    disabled={disabled}
                    onClick={() => handleToggle(item.REFERENCE_CODE)}
                >
                    {rf === 'ACTIVE_FLAG' ? item.REFERENCE_VALUE_3_TEXT : item.REFERENCE_VALUE_2_TEXT}
                    <div className="invalid-feedback">
                        Please enter a message in the textarea.
                    </div>
                </button>
            ))}
        </div>
    );
}

export default AbstractToggle;